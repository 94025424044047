// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analise-js": () => import("./../../../src/pages/analise.js" /* webpackChunkName: "component---src-pages-analise-js" */),
  "component---src-pages-aula-js": () => import("./../../../src/pages/aula.js" /* webpackChunkName: "component---src-pages-aula-js" */),
  "component---src-pages-calculadora-js": () => import("./../../../src/pages/calculadora.js" /* webpackChunkName: "component---src-pages-calculadora-js" */),
  "component---src-pages-desafio-js": () => import("./../../../src/pages/desafio.js" /* webpackChunkName: "component---src-pages-desafio-js" */),
  "component---src-pages-ebook-receitas-js": () => import("./../../../src/pages/ebook-receitas.js" /* webpackChunkName: "component---src-pages-ebook-receitas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recalculadora-js": () => import("./../../../src/pages/recalculadora.js" /* webpackChunkName: "component---src-pages-recalculadora-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-upsell-2-js": () => import("./../../../src/pages/upsell2.js" /* webpackChunkName: "component---src-pages-upsell-2-js" */),
  "component---src-pages-upsell-js": () => import("./../../../src/pages/upsell.js" /* webpackChunkName: "component---src-pages-upsell-js" */)
}

